/* eslint-disable */
import React, { useEffect } from "react";
import BannerSection from "./BannerSection";
import PowerbySoftwear from "./PowerbySoftwear";
import { useLocation } from "react-router-dom";

const Home = (props) => {
    const { setLocationGet } = props;
    const LocationUrl = useLocation();

    useEffect(() => {
        if(LocationUrl.pathname) {
            setLocationGet(LocationUrl.pathname);
        }
    }, [ LocationUrl ]);

    return(<React.Fragment>
        <BannerSection />
        <PowerbySoftwear />
    </React.Fragment>);
}

export default Home;