/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import DefaultProfile from "../../assets/img/dummyimage.png";
import CustomMendotoryMsg from "../Common/CustomMendotoryMsg";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import SucessIcon from "../../assets/img/success.svg";
import CommonModal from "../Common/CommonModal/index";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { accountNavigate, updateUser, CallLogoutUser, getUser, updateProfilePic, GetProductsIdWise, getProductList } from "../../redux/actions";
import { VERIFY_ACCOUNT_DETAILS,PROFILE_UPDATE_API_URL, PROFILE_UPLOAD_API_URL, API_URL, GET_PROFILE_API_URL, GET_PRODUCT_API_URL } from "../Shared/constant";
import ProfileLoader from "../../assets/img/spinner_transferent.svg";
import RedeemCreditsModal from "../Common/RedeemCreditsModal";
import CreditRequestLists from "./CreditRequestLists";
import CashPaymentIcon from "../../assets/img/cash-payment.png";
import Verify from "../../assets/img/verify.png";
import AddressRemoveModal from "./AddressRemoveModal";
import Withdrawalmethods from "./Withdrawalmethods";

const Account = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const { accessToken, profiledata, accountUrl, products } = useSelector((state) => state.allReducers);
    const [ CustomError, setCustomError ] = useState(false);
    const [ fname, setFname ] = useState((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
    const [ lname, setLname ] = useState((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
    const [ profileUpload, setProfileUpload ] = useState(profiledata?.user_avatar_path === null ? "" : profiledata?.user_avatar_path);
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : (parseInt(profiledata?.phone?.split("-")[0]) || parseInt(profiledata?.phone?.split(" ")[0])),
        number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : (parseInt(profiledata?.phone?.split("-")[1]) || parseInt(profiledata?.phone?.split(" ")[1])),
    });
    const [ commonPopup, setCommonPopup ] = useState({
        open: false,
        title: "",
        description: "",
        buttontitle: "" 
    });
    const [ fileUploadLoader, setFileUploadLoader ] = useState(false);
    const [ RedeemCrditModalState, setRedeemCrditModalState ] = useState({
        open: false,
        title: "",
        RedeemData: {}
    });
    const [ MobileError, setMobileError ] = useState("");
    const [ RoutingNumber, setRoutingNumber ] = useState("");
    const [ AccountNumber, setAccountNumber ] = useState("");
    const [ bankname, setBankName] = useState("");
    const [ cryptoaddress, setCryptoAddress] = useState("");

    const [ BillingAddressOne, setBillingAddressOne ] = useState("");
    const [ BillingAddressTwo, setBillingAddressTwo ] = useState("");
    const [ errorBillingAddressOne, setErrorBillingAddressOne ] = useState("");
    const [ errorBillingAddressTwo, setErrorBillingAddressTwo ] = useState("");
    const [banknameerror,setBankNameError] = useState('')
    const [accountnameerror,setAccountNameError] = useState('')
    const [routingnumbererror,setRoutingNumberError] = useState('')
    const [ StoreSameAs, setStoreSameAs ] = useState(false);
    const [ RequestProcessModel, setRequestProcessModel ] = useState({
        open: false,
        data: {}
    });
    const [ emailCheckValid, setEmailCheckValid ] = useState((profiledata?.is_email_check === null || profiledata?.is_email_check === false) ? false : profiledata?.is_email_check);

    // code updated
    useEffect(() => {
        setFname((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
        setLname((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
        setProfileUpload(profiledata?.user_avatar_path === null ? "" : profiledata?.user_avatar_path);
        setMobileNo({
            ...MobileNo, 
            countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : (parseInt(profiledata?.phone?.split("-")[0]) || parseInt(profiledata?.phone?.split(" ")[0])),
            number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : (parseInt(profiledata?.phone?.split("-")[1]) || parseInt(profiledata?.phone?.split(" ")[1])),    
        });
        setRoutingNumber((profiledata?.routing_number === null || profiledata?.routing_number === "") ? "" : profiledata?.routing_number);
        setAccountNumber((profiledata?.account_number === null || profiledata?.account_number === "") ? "" : profiledata?.account_number);
        setBankName((profiledata?.bank_name === null || profiledata?.bank_name === "") ? "" : profiledata?.bank_name);
        setCryptoAddress((profiledata?.wallet_address === null || profiledata?.wallet_address === "") ? "" : profiledata?.wallet_address);
        setBillingAddressOne((profiledata?.billing_address1 === null || profiledata?.billing_address1 === "") ? "" : profiledata?.billing_address1);
        setBillingAddressTwo((profiledata?.billing_address2 === null || profiledata?.billing_address2 === "") ? "" : profiledata?.billing_address2);
        setEmailCheckValid((profiledata?.is_email_check === null || profiledata?.is_email_check === false) ? false : profiledata?.is_email_check);
    }, [ profiledata ]);

    // add credit modal
    const addredeemCredit = (data) => {
        setRedeemCrditModalState({
            ...RedeemCrditModalState,
            open: !RedeemCrditModalState.open,
            title: `Redeem credits for ${data?.name}`,
            RedeemData: data,
        });
    };

    useEffect(() => {
        if(accountUrl !== "my-account") {
            if(accessToken !== "") {
                // get my product
                window.axios.get( `${GET_PRODUCT_API_URL}/5000/1`, {
                  headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                      'Authorization': 'Bearer ' + accessToken
                  }
                }).then(function (result) {
                    if (result.data) {
                      dispatch(GetProductsIdWise(result.data.data));
                      dispatch(getProductList(result.data.data));
                    }
                }).catch(function (result) {
                    if(result?.response?.status === 403) {
                        toast.error("The session expired due to logging in on another device.");
                        dispatch(CallLogoutUser());
                        localStorage.removeItem("access_tokens");
                    } else {
                        dispatch(GetProductsIdWise([]));
                        dispatch(getProductList([]));
                    }
                });

            } 
        }
    }, [ accountUrl ]);

    useEffect(() => {
        if(accountUrl === "my-books" || accountUrl === "my-account") {
            if(accessToken !== "") {
                window.axios.get(GET_PROFILE_API_URL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }}).then(function (result) {
                    if(result?.status === 200) {
                        dispatch(getUser(result?.data?.data));
                    };
                }).catch(function (result) {
                    if(result?.response?.status === 403) {
                        dispatch(CallLogoutUser());
                        localStorage.removeItem("access_tokens");
                    }
                });

            };
        };
    }, [ accountUrl ]);

    // Discard Account Modal Function
    const DiscardAccount = () => {
        setCommonPopup({...commonPopup, 
            open: !commonPopup?.open, 
            title: "Discard Changes?", 
            description: "Are you sure you want to discard the changes you've made?", 
            buttontitle: "Discard"
        })
    };

    // Discard field modal function
    const DiscardAccountData = () => {
        setCommonPopup({...commonPopup, open: false});
        setFname((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
        setLname((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
        setMobileNo({...MobileNo, 
            countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : profiledata?.phone?.split("-")[0],
            number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : parseInt(profiledata?.phone?.split("-")[1])
        })
        setRoutingNumber((profiledata?.routing_number === null || profiledata?.routing_number === "") ? "" : profiledata?.routing_number);
        setAccountNumber((profiledata?.account_number === null || profiledata?.account_number === "") ? "" : profiledata?.account_number);
        setBillingAddressOne((profiledata?.billing_address1 === null || profiledata?.billing_address1 === "") ? "" : profiledata?.billing_address1);
        setBillingAddressTwo((profiledata?.billing_address2 === null || profiledata?.billing_address2 === "") ? "" : profiledata?.billing_address2);
        setEmailCheckValid((profiledata?.is_email_check === null || profiledata?.is_email_check === false) ? false : profiledata?.is_email_check);
    };

    // profile update function
    const ProfileUpdate = () => {

        document.getElementById("accountflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && MobileNo?.number?.toString()?.length === 10) {
            // if(emailCheckValid && (BillingAddressOne == "" || BillingAddressTwo == "")) {
            //     if(BillingAddressOne == ""){
            //         setErrorBillingAddressOne("Billing Address 1 cannot be empty")
            //     }
            //     if(BillingAddressTwo == ""){
            //         setErrorBillingAddressTwo("Billing Address 2 cannot be empty")
            //     }
            //     return null;
            // }
            if(loader == false){
                setLoader(true);
                const jsonData = JSON.stringify({ 
                    first_name: fname,
                    last_name: lname,
                    phone: MobileNo?.countrycode + "-" + MobileNo?.number,
                    routing_number: RoutingNumber,
                    account_number: AccountNumber,
                    billing_address1: BillingAddressOne,
                    billing_address2: BillingAddressTwo,
                    is_email_check: emailCheckValid,
                    bank_name: bankname,
                    wallet_address:cryptoaddress,
                    payment_method: profiledata?.payment_method,
                });
                window?.axios?.post(PROFILE_UPDATE_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    dispatch(updateUser(result?.data?.data));
                    setMobileError("");
                    setErrorBillingAddressOne("");
                    setErrorBillingAddressTwo("");
                    toast.success(result.data.msg);
                    setLoader(false);
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                    if(result?.response?.status === 403) {
                        dispatch(CallLogoutUser());
                        localStorage.removeItem("access_tokens");
                    };
                    setLoader(false);
                });
            }
        } else {
            setCustomError(true);
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        }
    }

    // upload avatar image
    const UploadAvatarImage = (e) => {
        setFileUploadLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("avatar", files, files.name);

        window.axios.post( PROFILE_UPLOAD_API_URL, formData, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setProfileUpload(result.data.data.user_avatar_path);
                dispatch(updateProfilePic(result.data.data.user_avatar_path));
                setTimeout(() => setFileUploadLoader(false), 100);
            }
        }).catch(function (result) {
            if(result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
            } else {
                setTimeout(() => setFileUploadLoader(false), 100);
            }
        });
    };

    const checkSame = (e) =>{
        setStoreSameAs(e.target.checked);
        if(e.target.checked){
            setBillingAddressTwo(BillingAddressOne);
        }
    }

    // responsive tab change
    const selctResponsiveTab = (value) => {
        if(value === "My Books") {
            dispatch(accountNavigate("my-books"));
        } else if(value === "My Account") {
            dispatch(accountNavigate("my-account"));
        } else if(value === "Credit Request") {
            dispatch(accountNavigate("credit-request-list"));
        }else if(value === "Withdrawal Methods") {
            dispatch(accountNavigate("withdrawal-methods"));
        }else {
            dispatch(accountNavigate("card-details"))
        }
    };

    // empty space validation fucntion
    const EmptySpaceFieldValid = (e) => {
        if (e.target.value.length === 0 && e.which === 32) {
        return e.preventDefault();
        };
    };

    // get game name
    const GetGameName = (id) => {
        const ProductNameShow = products.find((elm) => elm?.id === id);
        return ProductNameShow?.name;
    };

    // Tokenizing card
    const TokenizingCards = () => {
        const TokenNiztion = document.getElementById("iframPayment");
        const iframPaymentBack = document.getElementById("iframPaymentBack");
        TokenNiztion.style.display = "block";
        iframPaymentBack.style.display = "block";
        window.myGlobalFunction();
        // document.getElementById("pageisLoading").style.display = "flex";
        // setTimeout(() => {
        //     window.open('./payment.html', '_blank');
        //     document.getElementById("pageisLoading").style.display = "none";
        // }, 5000);
    };
    const [showModal , setShowModal] = useState(false)
    const onChangeCheck = (e) =>{
        if(!e.target.checked){
            if(profiledata.billing_address1 || profiledata.billing_address2){
                setShowModal(true)
            }else{
                setEmailCheckValid(e.target.checked);
            }
            setErrorBillingAddressOne("")
            setErrorBillingAddressTwo("")
        }else{
            setEmailCheckValid(e.target.checked);
        }

    }
    const callBackModal = () =>{
        const jsonData = JSON.stringify({ 
            billing_address1: "",
            billing_address2: "",
            is_email_check: false,
            first_name: profiledata?.first_name,
            last_name: profiledata?.last_name,
            phone: MobileNo?.countrycode + "-" + MobileNo?.number,
            routing_number: profiledata?.routing_number,
            account_number: profiledata?.account_number,
            bank_name: bankname,
            wallet_address:cryptoaddress
        });
        window?.axios?.post(PROFILE_UPDATE_API_URL, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            dispatch(updateUser(result?.data?.data));
            setMobileError("");
            toast.success(result.data.msg);
        })  
        .catch(function (result) {
            toast.error(result.response.data.error);
            if(result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
            };
        });
    }
    const [initialBankName, setInitialBankName] = useState(profiledata?.bank_name ? profiledata?.bank_name : bankname);
    const [initialRoutingNumber, setInitialRoutingNumber] = useState(profiledata?.routing_number ? profiledata?.routing_number : RoutingNumber);
    const [initialAccountNumber, setInitialAccountNumber] = useState(profiledata?.account_number ? profiledata?.account_number : AccountNumber);

    const [accverify, setAccVerify] = useState(profiledata?.paynote_verified);
    const [inputChanged, setInputChanged] = useState(false);
    
    const handleInputChange = (setter, value, initialValue) => {
        setter(value);
        const hasChanged = value !== initialValue;
        setAccVerify(hasChanged); 
        setInputChanged(hasChanged); 
    };
    
    
    const areInputsEmpty = () => {
        return bankname === "" && RoutingNumber === "" && AccountNumber === "";
    };

    const [loading,setLoading]  = useState(false)
    const AccountVerificatataion = () =>{
        setLoading(true)
        window?.axios?.get(VERIFY_ACCOUNT_DETAILS,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            // console.log("result",result); // Log the entire result object for debugging
            if (result?.data?.data?.status === true) {
                toast.success(result?.data?.msg);
                setAccVerify(true);
                setInputChanged(false);  
                dispatch(getUser({
                    ...profiledata,
                    paynote_verified: true
                }));
                setLoading(false); 
            } else {
                setLoading(false); 
            }
                })  
        .catch(function (result) {
            // toast.error(result?.response?.data?.messages);
            setLoading(false)
            const errorMessage = result?.response?.data?.messages;

            if (Array.isArray(errorMessage)) {
                errorMessage?.forEach((msg) => {
                    toast?.error(msg);
                });
            } else if (typeof errorMessage === "string") {
                toast.error(errorMessage);
            }
            if(result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
            };
        });

    }
    const handleNumericInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };


    return(<section className="accoutntwrapper">
        <div className="container custom_container_new">
            <div className="myacountsatabswrps">
                <div className="row" style={{ margin: "auto" }}>
                    <div className="col-md-3">
                        <div className="myaccountprofilepic">
                            <div className="acctprofile">
                                <img src={profileUpload} alt="profile" onError={(e)=> {e.target.src = DefaultProfile}} />
                                <label className="uploadfileovarlay">
                                    <div className="svgwrp">
                                        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path fillRule="evenodd" d="M14.267,4 C14.801,4 15.299,4.287 15.566,4.75 L15.566,4.75 L16.144,5.75 C16.233,5.904 16.399,6 16.577,6 L16.577,6 L19.5,6 C20.327,6 21,6.673 21,7.5 L21,7.5 L21,17.5 C21,18.327 20.327,19 19.5,19 L19.5,19 L4.5,19 C3.673,19 3,18.327 3,17.5 L3,17.5 L3,7.5 C3,6.673 3.673,6 4.5,6 L4.5,6 L7.435,6 C7.609,6 7.773,5.907 7.863,5.758 L7.863,5.758 L8.483,4.727 C8.752,4.278 9.245,4 9.769,4 L9.769,4 Z M14.267,5 L9.769,5 C9.594,5 9.43,5.093 9.34,5.242 L9.34,5.242 L8.72,6.273 C8.451,6.722 7.958,7 7.435,7 L7.435,7 L4.5,7 C4.224,7 4,7.225 4,7.5 L4,7.5 L4,17.5 C4,17.775 4.224,18 4.5,18 L4.5,18 L19.5,18 C19.776,18 20,17.775 20,17.5 L20,17.5 L20,7.5 C20,7.225 19.776,7 19.5,7 L19.5,7 L16.577,7 C16.043,7 15.545,6.713 15.278,6.25 L15.278,6.25 L14.7,5.25 C14.611,5.096 14.445,5 14.267,5 L14.267,5 Z M11.9996,7.9999 C13.2656,7.9999 14.4706,8.6099 15.2236,9.6329 C15.9876,10.6719 16.1996,11.9939 15.8046,13.2609 C15.4326,14.4579 14.4576,15.4329 13.2606,15.8049 C12.8426,15.9349 12.4176,15.9989 11.9996,15.9989 C11.1516,15.9989 10.3286,15.7349 9.6336,15.2229 C8.6106,14.4699 7.9996,13.2659 7.9996,11.9999 C7.9996,9.7939 9.7946,7.9999 11.9996,7.9999 Z M11.9996,8.9999 C10.3456,8.9999 8.9996,10.3459 8.9996,11.9999 C8.9996,12.9489 9.4586,13.8529 10.2256,14.4169 C11.0056,14.9919 12.0026,15.1479 12.9636,14.8499 C13.8506,14.5729 14.5736,13.8519 14.8496,12.9639 C15.1486,12.0029 14.9916,11.0059 14.4176,10.2259 C13.8526,9.4579 12.9496,8.9999 11.9996,8.9999 Z"></path></svg>
                                    </div>
                                    <input type="file" onChange={UploadAvatarImage} accept=".svg, .png, .jpg, .jpeg, .webp" />
                                </label>
                                {fileUploadLoader === true && <div className="imageloader"><img src={ProfileLoader} alt="loader"/></div>}
                            </div>
                            <h4>{(profiledata.first_name === null && profiledata.last_name === null) ? profiledata?.email : 
                                (profiledata.first_name !== null ? profiledata.first_name : "") + " " + (profiledata.last_name !== null ? profiledata.last_name : "")}</h4>
                        </div>
                        {screen.width > 767 ? (<div className="accountsRouting">
                            <a className={accountUrl === "my-books" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("my-books"))}>Member Dashboard</a>
                            <a className={accountUrl === "my-account" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("my-account"))}>My Account</a>
                            <a className={accountUrl === "withdrawal-methods" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("withdrawal-methods"))}>Withdrawal Methods</a>
                            <a className={accountUrl === "credit-request-list" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("credit-request-list"))}>Credit Request List</a>
                            {/* <a className={accountUrl === "card-details" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("card-details"))}>Payment Card</a> */}
                        </div>): (<select className="selectAccountssct" onChange={(e) => selctResponsiveTab(e.target.value)}>
                            <option value="My Books" selected={accountUrl === "my-books" ? "selected" : ""}>Member Dashboard</option>
                            <option value="My Account" selected={accountUrl === "my-account" ? "selected" : ""}>My Account</option>
                            <option value="Withdrawal Methods" selected={accountUrl === "withdrawal-methods" ? "selected" : ""}>Withdrawal Methods</option>
                            <option value="Credit Request" selected={accountUrl === "credit-request-list" ? "selected" : ""}>Credit Request List</option>
                            {/* <option value="Card Details" selected={accountUrl === "card-details" ? "selected" : ""}>Payment Card</option> */}
                        </select>)}
                    </div>  
                    <div className="col-md-9">
                        {accountUrl === "my-account" ? (
                            <div className="myaccountwrps">
                            <div className="myaccountwrpsheader">
                                <div className="headertitle">
                                    <h4>My Account</h4>
                                    <p>View and edit your personal info below.</p>
                                </div>
                                <div className="bttngroup">
                                    <Button className="btn discardbtn" onClick={() => DiscardAccount()}>Discard</Button>
                                    <Button className="btn updteinfobtn" onClick={() => ProfileUpdate()}>Update Info</Button>
                                </div>
                            </div>
                            <div className="accountwrps">
                                <h4>Account</h4>
                                <h5>Update your personal information.</h5>
                                <div className="paymentformsWraps">
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                            <label>Login Email:</label>
                                            <div className="emailset">{profiledata?.email}</div>
                                            <div className="noteemail">Your Login email can't be changed</div>
                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                            <label>First Name *</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" />
                                                {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                                            </div>
                                        </div>
                                        <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                                            <label>Last Name *</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" />
                                                {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                                            <label>Phone *</label>
                                            <div className="form-groupfiled">
                                                <CountrySelectInput 
                                                    MobileNo={MobileNo}
                                                    setMobileNo={setMobileNo}
                                                    id={"accountflag"}
                                                />
                                                {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="rowcustom">
                                        <div className="termscondtaceptsection">
                                            <div className="form-group-checkbox">
                                                <input type="checkbox" id="emailcheck" onChange={onChangeCheck} checked={emailCheckValid} />
                                                <label htmlFor="emailcheck">Preferred Method: Mailed Cheque</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustom">
                                        <div className="form-group">
                                            <label>Billing Address 1 {emailCheckValid && "*"}</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={BillingAddressOne} onChange={(e) => {setBillingAddressOne(e.target.value); setErrorBillingAddressOne("")}} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Billing Address 1" />
                                                {BillingAddressOne !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {errorBillingAddressOne && <small className="text-danger"> {errorBillingAddressOne}</small>}
                                        </div>
                                    </div>
                                    <div className="rowcustom">
                                        <div className="termscondtaceptsection">
                                            <div className="form-group-checkbox">
                                            <input type="checkbox" id="StoreSameAs" onChange={checkSame} disabled={BillingAddressOne == "" && BillingAddressTwo=="" ?true: false} checked={StoreSameAs} />
                                                <label htmlFor="StoreSameAs">Billing address 1 Same Billing address 2 </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustom">
                                        <div className="form-group">
                                            <label>Billing Address 2 {emailCheckValid && "*"}</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" 
                                                 disabled={StoreSameAs}
                                                 value={BillingAddressTwo}
                                                 onChange={(e) =>{ setBillingAddressTwo(e.target.value); setErrorBillingAddressTwo("")}} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Billing Address 2" />
                                                {BillingAddressTwo !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {errorBillingAddressTwo && <small className="text-danger"> {errorBillingAddressTwo}</small>}
                                        </div>
                                    </div>

                                    <div className="rowcustom">
                                        <div className="form-group">
                                        <div className="Verify_wrapper">
                                            <label>Bank Name  {(RoutingNumber !== "" || AccountNumber !== "") && "*"}</label>

                                      {profiledata?.paynote_verified ? (
                                        <div className="verified">
                <span>Verified</span>
                <div>
                    <img alt="verify_img" src={Verify} />
                </div>
                </div>
                   
                ):
                <div className="verify">
                        <button disabled={loading} onClick={AccountVerificatataion}>Verify</button>
                    </div>
                }
      
                                        </div>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={bankname}    
                                                onChange={(e)=>{
                                                    const bankName = e.target.value;
                                                    handleInputChange(setBankName, e.target.value, initialBankName);

                                                    if((RoutingNumber!=="" || AccountNumber !=="")&& bankName===""){
                                                        setBankNameError("Bank Name Cannot be empty")
                                                    }else{
                                                         setBankNameError("")     
                                                    }
                                                    // setBankName(bankName);  
                                                }}
                                                 onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                                 placeholder="Enter Bank Name" />
                                                {bankname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {(RoutingNumber !== "" || AccountNumber !== "") && <small className="text-danger">{banknameerror}</small>}

                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className="form-group">
                                            <label>Routing Number {bankname !== "" && "*"}</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={RoutingNumber}  
                                                onChange={(e)=>{                                                   
                                                    const routingNumber = e.target.value;
                                                    handleInputChange(setRoutingNumber, e.target.value, initialRoutingNumber);
                                                    if(bankname!==""&& routingNumber==="" ){
                                                        setRoutingNumberError("Routing Number Cannot be empty")
                                                    }else{
                                                        setRoutingNumberError("")
                                                    }
                                                    // setRoutingNumber(routingNumber);
                                                }} 
                                                onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                                onInput={handleNumericInput}

                                                placeholder="Enter Routing Number" autoComplete="off"/>
                                                {RoutingNumber !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {(bankname !== "") && <small className="text-danger">{routingnumbererror}</small>}
                                        </div>
                                        <div className="form-group">
                                            <label>Account Number {bankname !== "" && "*"}</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={AccountNumber}   
                                                onChange={(e)=>{
                                                    const accountNumber = e.target.value;
                                                    handleInputChange(setAccountNumber, e.target.value, initialAccountNumber);
                                                    if(bankname!=="" && accountNumber===""){
                                                        setAccountNameError("Account Number Cannot be empty")
                                                    }else{
                                                        setAccountNameError("")
                                                    }
                                                    //  setAccountNumber(accountNumber);
                                                }}
                                                onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                                onInput={handleNumericInput}

                                                placeholder="Enter Account Number" autoComplete="off"/>
                                                {AccountNumber !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {(bankname !== "") && <small className="text-danger">{accountnameerror}</small>}
                                        </div>
                                    </div>   */}
                                   
                                    {/* <div className="rowcustom">
                                        <div className="form-group">
                                            <label>Crypto Wallet Address </label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={cryptoaddress} onChange={(e) =>setCryptoAddress(e.target.value)} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Crypto Address" />
                                                {cryptoaddress !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>

                                        </div>
                                    </div> */}
                                    {/* <div className="rowcustom">
                                        <div className="form-group">
                                            <label>Billing Address 1 {emailCheckValid && "*"}</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={BillingAddressOne} onChange={(e) => {setBillingAddressOne(e.target.value); setErrorBillingAddressOne("")}} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Billing Address 1" />
                                                {BillingAddressOne !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {errorBillingAddressOne && <small className="text-danger"> {errorBillingAddressOne}</small>}
                                        </div>
                                    </div> */}

                                    {/* <div className="rowcustom">
                                        <div className="termscondtaceptsection">
                                            <div className="form-group-checkbox">
                                            <input type="checkbox" id="StoreSameAs" onChange={checkSame} disabled={BillingAddressOne == "" && BillingAddressTwo=="" ?true: false} checked={StoreSameAs} />
                                                <label htmlFor="StoreSameAs">Billing address 1 same Billing address 2 </label>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="rowcustom">
                                        <div className="form-group">
                                            <label>Billing Address 2 {emailCheckValid && "*"}</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" disabled={StoreSameAs} value={BillingAddressTwo} onChange={(e) =>{ setBillingAddressTwo(e.target.value); setErrorBillingAddressTwo("")}} onKeyDown={(e) => EmptySpaceFieldValid(e)} placeholder="Enter Billing Address 2" />
                                                {BillingAddressTwo !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                            </div>
                                            {errorBillingAddressTwo && <small className="text-danger"> {errorBillingAddressTwo}</small>}
                                        </div>
                                    </div> */}
                                    {/* <div className="rowcustom rowcustom-col-2">
                                        <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                                            <label>Phone *</label>
                                            <div className="form-groupfiled">
                                                <CountrySelectInput 
                                                    MobileNo={MobileNo}
                                                    setMobileNo={setMobileNo}
                                                    id={"accountflag"}
                                                />
                                                {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="rowcustomright" >
                                        <Button className="btn discardbtn" onClick={() => DiscardAccount()}>Discard</Button>
                                        <Button className="btn updteinfobtn" onClick={() => ProfileUpdate()} disabled={
                                         loader 
                                        //  ||
                                        //  (inputChanged && !areInputsEmpty()) ||
                                        // accverify || // Disable if already verified
                                        // !inputChanged || // Disable if no inputs have changed
                                        // !areInputsEmpty() || // Disable if all inputs are empty
                                        //  (bankname !== "" && (RoutingNumber === "" || AccountNumber === "")) ||
                                        //  ((RoutingNumber !== "" || AccountNumber !== "") && bankname === "") 
                                        }>Update Info</Button>
                                    </div>
                                </div>
                            </div>
                        </div>) : accountUrl === "my-books" ? (
                            <div className="redeemprice_wrapper">
                        <h4>REDEEM PRIZES BELOW</h4>
                        <div className="redeemprizewrapper">
                            <div className="row">
                                {products.length > 0 ? products && products?.map((elm, index) => {
                                    return(
                                        <div className="col-3" key={index}>
                                            <img src={elm?.product_img_path} onClick={() => addredeemCredit(elm)} alt={"redeem price_" + index} />
                                        </div>
                                    )
                                }) : (<h5>Games Not Found !</h5>)}
                            </div>
                        </div>
                    </div>) : accountUrl === "credit-request-list" ? (<CreditRequestLists />) : 
                    accountUrl === "withdrawal-methods" ? <Withdrawalmethods/> :
                    (<div className="card_details_wrapper">
                        {/* <div className="myaccountwrpsheader">
                            <div className="headertitle">
                                <h4>Payment Card</h4>
                            </div>
                            <div className="bttngroup">
                                    <Button className="btn updteinfobtn" id="TokenizingCrdbtn" onClick={() => TokenizingCards()}>
                                        {localStorage.getItem("SAVE_CARD") === "TRUE" ? "Update Payment Card" : "Secure Payment Card"}
                                    </Button>
                                </div>
                            </div> */}
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        {commonPopup?.open && (<CommonModal DiscardAccountData={DiscardAccountData} commonPopup={commonPopup} setCommonPopup={setCommonPopup} />)}
        {commonPopup?.open && (<div className="ModalBackground"></div>)}

        {RedeemCrditModalState?.open && (<RedeemCreditsModal 
       
        RequestProcessModel={RequestProcessModel} setRequestProcessModel={setRequestProcessModel} accessToken={accessToken} RedeemCrditModalState={RedeemCrditModalState} setRedeemCrditModalState={setRedeemCrditModalState} />)}
        {RedeemCrditModalState?.open && (<div className="redeembackground" onClick={() => setRedeemCrditModalState({...RedeemCrditModalState, open: false})}></div>)}

        {RequestProcessModel?.open && (<div className="statusPendingModel">
            <img src={CashPaymentIcon} alt="icon" />
            <h1>Your <b>{GetGameName(RequestProcessModel?.data.product_id)}</b> redeem credit request is currently in progress. You will receive a confirmation email regarding your credit request with the request id<div className="request_id_pass">{RequestProcessModel?.data?.request_id}</div></h1>
            <button className="btn" onClick={() => setRequestProcessModel({...RequestProcessModel, open: false, data: {}})} >Ok</button>
        </div>)}
        {RequestProcessModel?.open && (<div className="ModalBackground" onClick={() => setRequestProcessModel({...RequestProcessModel, open: false, data: {}})}></div>)}
        <AddressRemoveModal
         setStoreSameAs={setStoreSameAs}
         show={showModal} setModalState={setShowModal} callBackModal={callBackModal} />
    </section>)
}

export default Account;