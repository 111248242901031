/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCreditRequestList, ClearReduxFlow } from "../../redux/actions";
import { GET_CREDIT_REQUEST_LIST_API_URL } from "../Shared/constant";
import PaginationPage from "../Common/PaginationPage";
import { Table } from "react-bootstrap";
import DefaultImage from "../../assets/img/dummyimage.png";
import CloseIcon from "../../assets/img/closeIcon.png";

const CreditRequestLists = () => {
    const dispatch = useDispatch();
    const { accessToken, CreditRequestList } = useSelector((state) => state.allReducers);
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ userSearch, setUserSearch ] = useState("");
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ searchNone, setSearchNone ] = useState(false);
    const [ paginationLength, setPaginationLength ] = useState({});

    useEffect(() => {
        getCreditRequestFct();
    }, [ userType, userLimit, currentPage, searchNone ]);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // on Enter search
    const onEnterSearchNew = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                getCreditRequestFct(1);
            } else if(userSearch === "" || userSearch?.length <= 1) {
                getCreditRequestFct();
            };
        };
    };

    const getCreditRequestFct = (pages) => {
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        
        window.axios.get(`${GET_CREDIT_REQUEST_LIST_API_URL}/${userType}/${userLimit}/${pages === undefined ?currentPage : pages}/${userSearch === "" ? "none" : userSearch.trim()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            dispatch(getCreditRequestList(result.data.data));
            setPaginationLength(result.data.pagination);
            setSearchNone(false);
        })
        .catch(function (result) {
            dispatch(getCreditRequestList([]));
            if(result?.response?.status === 403) {
               dispatch(ClearReduxFlow());
               localStorage.removeItem("access_tokens");
            }
        });
    };

    const currentFunctionSet = (page) => {
        setCurrentPage(page);
    };

    const clearSearch = () => {
        setUserSearch("");
        setSearchNone(true);
    };

    return(<div className="gameListing">
        <h4>Credit Request List :</h4>
        <div className="card-header-New">
            <div className="searchBoxwithbtn" style={{ width: "324px" }}>
                {userSearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                <input type="text" className="form-control" style={{ fontSize: "13px" }} value={userSearch} onKeyDown={(e) => onEnterSearchNew(e)} onChange={(e) => setUserSearch(e.target.value)} placeholder="Search (Name,Email,Game Name,Request ID)" />
                {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => clearSearch()} alt="close" />)}
            </div>
            <div className="card-header-right">
                <select className="form-control activestatusselet" style={{ width: "125px" }} value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                    <option value="all">All</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="unprocessed">Unprocessed</option>
                    <option value="pending">Pending</option>
                </select>
                <select className="form-control userLimitselect" value={userLimit} onChange={(e) => setUserLimit(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
        <div className="table_set">
            <div className="table-responsive" style={{ width: window?.screen?.width < 700 ? window?.screen?.width - 40 + 'px' : "inherit" }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Request ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Game Name</th>
                            <th>Game Image</th>
                            <th>Credit</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CreditRequestList && CreditRequestList?.length === 0 ? (<tr>
                        <td colSpan="10" style={{ textAlign: "center" }}>No Record Found</td>
                            </tr>) : CreditRequestList?.map((orders, index) => {
                                return(<tr key={index}>
                                    <td>{orders?.id}</td>
                                    <td>{orders?.user_name}</td>
                                    <td>{orders?.user_email}</td>
                                    <td>{orders?.product_name}</td>
                                    <td className="userAvatar">
                                        <div className="previewImage">
                                            {orders?.product_img === null ? (<img src={DefaultImage} alt="profile" />) : (<img src={orders?.product_img} alt="profile" />)}
                                        </div>
                                    </td>
                                    <td>${orders?.approved_amount ?Number(orders?.approved_amount).toLocaleString():Number(orders?.amount).toLocaleString()}</td>
                                    <td>
                                        {/* {orders?.action === null ? <span className="label_status primary">Unprocessed</span> : orders?.action === "approved" ?<span className="label_status success">Approved</span> : <span className="label_status error">Rejected</span>}
                                        <span className="Pending_acc">Pending</span> */}
                                        {/* {orders?.action === true ? <span className="label_status success">Paid</span> : <span className="label_status error">Failed</span>} */}
                                        {/* {orders?.action === null ? } */}
                                        {orders?.action === null ? (
  <span className="label_status primary">Unprocessed</span>
) : orders?.action === "approved" ? (
  <span className="label_status success">Approved</span>
) : orders?.action === "pending" ? (
    <span className="Pending_acc">Pending</span>
) : (
  <span className="label_status error">Rejected</span>
)}

                                    </td>
                                </tr>)
                            })}
                    </tbody>
                </Table>
            </div>
        </div>
        {CreditRequestList?.length > 0 && (<PaginationPage 
            paginationLength={paginationLength}
            totalPages={paginationLength?.total_records}
            currentPage={currentPage}
            onChangePage={currentFunctionSet}
            userLimit={userLimit}
        />)}
    </div>)
}

export default CreditRequestLists;