/* eslint-disable */
import React, { useRef , useState} from "react";
import Webcam from "react-webcam";
import { Image } from "react-bootstrap";
import Cancel from "../../assets/img/close_sidebar.png";
import Camera from "../../assets/img/camerabutton.png";
import Send from "../../assets/img/sendCaptureimage.png";
import Delete from "../../assets/img/deleteMessageImage.png";
import CameraAccessPopup from "../Common/CameraAccessPopup";
// import RighSideLoader from "../common/sidebar/RighSideLoader";

const CameraTakePhoto = (props) => {
    const { setCameraOpen, setImgSrc, imgSrc, UploadFile } = props;
    const webcamRef = useRef(null);
    const [ prviewCaptureImage, setPrviewCaptureImage ] = useState(null);
    const [ fileBlob, setFileBlob ] = useState("");
    const [ accessToRecording, setAccessToRecording ] = useState(true);
    const [ loader, setLoader ] = useState(0);
    const [ buttonLoader, setButtonLoader ] = useState(false);

    // camera capture function
    const capture =async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        if(imageSrc) {
            urltoFile(imageSrc, 'demo.jpeg','image/jpeg').then(function(file){ 
                setFileBlob(file);
                setPrviewCaptureImage(imageSrc);
            });
        };
    }

    // base64 image convert to file object like as from file input form
    const urltoFile = async (url, filename, mimeType) => {
        if (url.startsWith('data:')) {
            var arr = url.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            var file = new File([u8arr], filename, {type:mime || mimeType});
            return Promise.resolve(file);
        }
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], filename, { type: mimeType });
    };

    // uploaded image send 
    const ImageUpload = async () => {
        // setImgSrc({...imgSrc, preview: prviewCaptureImage, objectfile: fileBlob});
        setImgSrc(prviewCaptureImage);
        UploadFile(fileBlob, "face", "face_image");
        setCameraOpen(false);
    };

    // camera allow or not check function
    const handleUserMedia = () => {
        setAccessToRecording(true);
    }
    const handleUserMediaError = () => {
        setAccessToRecording(false);
    }

    // close camera modal
    const CloseCameraModal = () => {
        setCameraOpen(false);
        setImgSrc("");
    };

    return(
        <div onClick={(e)=>e.stopPropagation()} className="ImageViewPreviewModal captureImage">
            <div className='controlbars'>
                <div>
                    
                </div>
                <Image src={Cancel} onClick={() => CloseCameraModal()} alt="Cancel" />
            </div>
            {accessToRecording === true ? (<React.Fragment>
                {prviewCaptureImage === null ? (<div className="imageViewMain imagecapturemain">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        onUserMedia={handleUserMedia}
                        onUserMediaError={handleUserMediaError}	
                    />
                    <div className="btnGroupswrapper">
                        <div className="campturebtncmera" onClick={() => buttonLoader ===false && capture()}>
                            <Image  src={Camera} alt="Camera" />
                        </div>
                    </div>
                </div>) : (
                    <div className="imageViewMain imagecapturemain">
                        <Image src={prviewCaptureImage}  alt="camara capture" />
                        <div className="btnGroupswrapper">
                            <div className="campturImagesend">
                                <Image src={Send} onClick={() => ImageUpload()} alt="Camera" />
                            </div>
                            <div 
                                className="camptureImageDelete" 
                                onClick={() => {
                                    setPrviewCaptureImage(null);
                                    setImgSrc("");
                                }}
                            >
                                <Image src={Delete} alt="Camera" />
                            </div>
                        </div>
                    </div>
                )} 
        </React.Fragment>) : (<CameraAccessPopup setCameraOpen={setCameraOpen} accessToRecording={accessToRecording} />)}
    </div>
    )
}

export default CameraTakePhoto;