export const ActionTypes = {
    GET_SIGNLE_POSTER: "GET_SIGNLE_POSTER",
    ADD_TO_CART: "ADD_TO_CART",
    SELECT_POSTER_MULTI: "SELECT_POSTER_MULTI",
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
    CARD_DETAILS: "CARD_DETAILS",
    PRESELECT_MULTI_POSTER: "PRESELECT_MULTI_POSTER",
    IS_LOGIN: "IS_LOGIN",
    GET_USER: "GET_USER",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    UPDATE_USER: "UPDATE_USER",
    UPDATE_PROFILE_PIC: "UPDATE_PROFILE_PIC",
    ACCOUNT_NAVIGATE: "ACCOUNT_NAVIGATE",
    GET_MY_PRODUCT: "GET_MY_PRODUCT",
    ADD_MY_PRODUCT: "ADD_MY_PRODUCT",
    GET_PRODUCT_ID_WISE: "GET_PRODUCT_ID_WISE",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    SET_LOGOUT_USER: "SET_LOGOUT_USER",
    GET_GEO_COMPLY_LOCATION: "GET_GEO_COMPLY_LOCATION",
    IS_SITE_IS_BLOCK: "IS_SITE_IS_BLOCK",
    GET_IP_ADDRESS: "GET_IP_ADDRESS",
    SPOOFING_DETECTION: "SPOOFING_DETECTION",
    SET_CLEAR_REDUX_FLOW:"SET_CLEAR_REDUX_FLOW",
    GEOCOMPLY_ISSUE_MESSAGE:"GEOCOMPLY_ISSUE_MESSAGE",
    GET_UNIQUE_BROWSER_ID: "GET_UNIQUE_BROWSER_ID",
    GET_LICENSE_ERROR_MESSAGE: "GET_LICENSE_ERROR_MESSAGE",
    STOP_OVERCALLING_GEOLOCATION: "STOP_OVERCALLING_GEOLOCATION",
    GET_REGEOLC_TIME: "GET_REGEOLC_TIME",
    GET_CARD_DETAILS_STORE: "GET_CARD_DETAILS_STORE",
    DELETE_CARD_DETAILS_STORE: "DELETE_CARD_DETAILS_STORE",
    GET_CREDIT_REQUEST_LIST: "GET_CREDIT_REQUEST_LIST"
};

export const getLicenseCoplyMessage = (message) => {
    return {
        type: ActionTypes.GET_LICENSE_ERROR_MESSAGE,
        payload: message,
    }
};

export const getRegioLcTime = (time) => {
    return {
        type: ActionTypes.GET_REGEOLC_TIME,
        payload: time,
    }
}

export const getOverCallingGeoLocation = (location) => {
    return {
        type: ActionTypes.STOP_OVERCALLING_GEOLOCATION,
        payload: location,
    }
};

export const getGeoCoplyMessage = (message) => {
    return {
        type: ActionTypes.GEOCOMPLY_ISSUE_MESSAGE,
        payload: message,
    }
};

export const getUniqueBrowserId = (id) => {
    return {
        type: ActionTypes.GET_UNIQUE_BROWSER_ID,
        payload: id,
    }
};

export const getGeoCoplyLocation = (data_) => {
    return {
        type: ActionTypes.GET_GEO_COMPLY_LOCATION,
        payload: data_,
    }
};

export const getSpoofingDetection = (data_) => {
    return {
        type: ActionTypes.SPOOFING_DETECTION,
        payload: data_,
    }
};

export const GetIPAddress = (ipaddress) => {
    return {
        type: ActionTypes.GET_IP_ADDRESS,
        payload: ipaddress,
    }
};

export const IsSiteIsBlockCheck = (site) => {
    return {
        type: ActionTypes.IS_SITE_IS_BLOCK,
        payload: site,
    }
};

export const preSelectMultiPoster = (posters) => {
    return {
        type: ActionTypes.PRESELECT_MULTI_POSTER,
        payload: posters,
    }
};

export const getProductList = (products) => {
    return {
        type: ActionTypes.GET_PRODUCT_LIST,
        payload: products,
    }
};

export const CallLogoutUser = () =>{
    return {
        type: ActionTypes.SET_LOGOUT_USER,
    }
}

export const ClearReduxFlow = () =>{
    return {
        type: ActionTypes.SET_CLEAR_REDUX_FLOW,
    }
}

export const getSinglePosters = (posters) => {
    return {
        type: ActionTypes.GET_SIGNLE_POSTER,
        payload: posters,
    }
};

export const AddToCartPosters = (posters) => {
    return {
        type: ActionTypes.ADD_TO_CART,
        payload: posters,
    }
};

export const SelectMultiPosters = (posters) => {
    return {
        type: ActionTypes.SELECT_POSTER_MULTI,
        payload: posters,
    }
}

export const customerDetailsGet = (customer) => {
    return {
        type: ActionTypes.CUSTOMER_DETAILS,
        payload: customer,
    }
}

export const cardDetailsGet = (card) => {
    return {
        type: ActionTypes.CARD_DETAILS,
        payload: card,
    }
}

export const accountNavigate = (link) => {
    return {
        type: ActionTypes.ACCOUNT_NAVIGATE,
        payload: link,
    }
}

export const GetMyProducts = (products) => {
    return {
        type: ActionTypes.GET_MY_PRODUCT,
        payload: products,
    }
}

export const GetProductsIdWise = (products) => {
    return {
        type: ActionTypes.GET_PRODUCT_ID_WISE,
        payload: products,
    }
}

export const AddMyProduct = (product) => {
    return {
        type: ActionTypes.ADD_MY_PRODUCT,
        payload: product,
    }
}

export const GetCategory = (categorys) => {
    return {
        type: ActionTypes.GET_CATEGORY,
        payload: categorys,
    }
}

export const getIdWiseCategoryList = (category) => {
    return {
        type: ActionTypes.ID_WISE_CATEGORY,
        payload: category,
    }
}

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const getUser = (user) => {
    return {
        type: ActionTypes.GET_USER,
        payload: user,
    }
}

export const updateProfilePic = (profile) => {
    return {
        type: ActionTypes.UPDATE_PROFILE_PIC,
        payload: profile,
    }
}

export const updateUser = (user) => {
    return {
        type: ActionTypes.UPDATE_USER,
        payload: user,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.ACCESS_TOKEN,
        payload: token,
    }
}

export const getCardDetailsStore = (cardDetails) => {
    return {
        type: ActionTypes.GET_CARD_DETAILS_STORE,
        payload: cardDetails,
    }
}

export const deleteCardDetailsStore = (cardDetails) => {
    return {
        type: ActionTypes.DELETE_CARD_DETAILS_STORE,
        payload: cardDetails,
    }
}

export const getCreditRequestList = (list) => {
    return {
        type: ActionTypes.GET_CREDIT_REQUEST_LIST,
        payload: list,
    }
}