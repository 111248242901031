import React from "react";

const PowerbySoftwear = () => {
    return(<section className="powerbysections">
        <div className="container container_header">
            <div className="row">
                <div className="col-md-12">
                    <h1>Powered by Nationwide Software</h1>
                    <p>At Sweeps Coins Cash we're committed to offering you the best Play-From-Home systems at your convenience, for the grand possibility of you walking away a WINNER!!</p>
                </div>
            </div>
        </div>
    </section>);
}

export default PowerbySoftwear;