export const HOME_URL = "/";
export const MEMBER_DASHBOARD = "/member-dashboard";
export const MY_ACCOUNT = "/my-account";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_CONDITIONS = "/terms-and-conditions";
export const PROMOTIONAL_RULES = "/promotional-rules";
export const CONTACT = "/contact";
export const RESET_PASSWORD = "/reset-password";
export const USER_DATA_DETECTION = "/user-data-deletion";

// Api urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const RESET_PASSWORD_API_URL = API_URL + "user/create-password/";
export const LOGIN_API_URL = API_URL + "user/login";
export const SIGN_UP_API_URL = API_URL + "user/sign-up";
export const PHOTO_VERIFICATION_API_URL = API_URL + "user/photo-verification";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const FORGOT_PASSWORD_API_URL = API_URL + "user/forgot-password";
export const CREATE_PASSWORD_API_URL = API_URL + "user/create-password";
export const FILE_UPLOAD_API_URL = API_URL + "file/profile-upload";
export const PROFILE_UPDATE_API_URL = API_URL + "user/profile-update";
export const GET_PROFILE_API_URL = API_URL + "user/get";
export const PROFILE_UPLOAD_API_URL = API_URL + "file/update-profile";
export const SIGNUP_PROFILE_UPLOAD = API_URL + "file/upload-profile";
export const PROFILE_ID_UPLOAD = API_URL + "file/upload/photo-id";
export const GET_PRODUCT_API_URL = API_URL + "product/list";
export const PLACE_PRODUCT_ORDER_API_URL = API_URL + "user/place-order";
export const PAYMENT_PLACE_ORDER_API_URL = API_URL + "user/payment";
export const GET_MY_PRODUCT = API_URL + "user/my-products";
export const PRE_SELECTED_PRODUCT = API_URL + "product/pre-selected/";
export const CONTACT_US_API_URL = API_URL + "contact-us";
export const SUBSCRIBE_API_URL = API_URL + "subscribe";
export const SOCIAL_FIREBASE_API_URL = API_URL + "user/social-auth";
export const USER_CREDIT_REQUEST_API_URL = API_URL + "user/credit-request/";
export const ALL_SESSION_LOGOUT_API_URL = API_URL + "user/auth";
export const USER_VALID_LOGIN_API_URL = API_URL + "user/validate/login";
export const FACEBOOK_LOGIN_API_URL = API_URL + "/user/facebook/login";
export const VERIFY_ACCOUNT_DETAILS = API_URL + "user/attach/account"

export const ADD_CARD_API_URL = API_URL + "user/add-card";
export const UPDATE_CARD_API_URL = API_URL + "user/update-card";
export const GET_CARD_DETAILS_API_URL = API_URL + "user/card-detail";
export const DELETE_CARD_API_URL = API_URL + "user/delete-card";
export const GET_CREDIT_REQUEST_LIST_API_URL = API_URL + "user/credit/list";

export const GET_GEO_LOCATE_LICENSE = API_URL + "user/geocomply/license";
export const GET_GEO_LOCATE_PERMISSION_LICENSE = API_URL + "user/geocomply/permission";

export const GET_USER_OCT_AUTH = API_URL + "user/oct-auth";